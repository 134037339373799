<template>
    <div>
        <v-menu 
            v-model="menuSelect" 
            :close-on-content-click="false" 
            offset-y  offset-overflow
            :nudge-width="90" rounded="xl"
            >
            <template v-slot:activator="{ on, attrs }"> 
                <v-card   v-on="on" class="transparent"
                    width="100%" min-width="150"
                     min-height="20" tile flat>
                    <v-layout fill-height align-center class="ma-0 pl-2 pb-1 pt-1"> 
                       <div class="font-weight-bold not-f3 grey--text text--darken-1">
                           {{selectedCompany?selectedCompany.company_name:'Select..'}}
                        </div> 
                       <v-icon class="ml-2">arrow_drop_down</v-icon>
                    </v-layout> 
                </v-card> 
            </template> 

            <v-card  v-if="ud"  
                color="lighten-1" class="">  
                <v-card-title class="secondary white--text" dark>
                    Switch Company
                </v-card-title> 
                <v-list dense  ref="body_height" :max-height="vsb.scroll?vsh-175:''" :class="vsb.scroll?'mbs-scroll-y':''">
                    <v-list-item @click="MBS.actions.go('/account/companies');menuSelect=false">See all companies</v-list-item> 
                    <v-list-item @click="MBS.actions.go('/account/companies');menuSelect=false">Add a new company</v-list-item> 
                    <v-divider></v-divider>
                    <div v-for="(company,index) in JoinedMyCompanies" :key="index">
                        <v-list-item :class="selectedCompany?selectedCompany.company_key ==company.company_key?'secondary--text':'':''" @click="SWITCH_COMPANY(company)">{{company.company_name}}</v-list-item> 
                    </div>
                </v-list>  
                <v-divider></v-divider> 
                <!-- //FOOTER  -->
                <v-card-actions class="pa-0">
                    <v-btn @click="menuSelect=false" text width="100%" color="secondary">Cancel</v-btn>
                </v-card-actions>
            </v-card>  
        </v-menu>  
    </div>
</template> 
<script>
let PAGE_NAME = 'COMPONY-SELECT'
import DATA from "../../plugins/DATA"
import DB from "../../plugins/DB"
import errors from '../../store/errors'
export default { 
    data(){
        return{  
            body_height:0,
            dialog:false, 
            input:{}, 
            menuSelect:false
        } 
    }, 
    created() {
        try {
            this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            // this.MBS.events.$on('ITEM_ACTION', this.ITEM_ACTION);  
            
        } catch (error) {
            this.MBS.actions.error({
                error:error,
                from:'created',
                page:PAGE_NAME, 
            }) 
        }
    },
    mounted(){
        this.input={}
        this.inputLoading=false  
    },
    computed:{
        route(){
            return this.$route
        },
        vs(){
            return this.$vuetify.breakpoint
        }, 
        vsh(){
            return this.vs.height
        },
        vsb(){ 
            let body_height = this.body_height 
            let height = this.vs.height 
            body_height = this.$refs.body_height?this.$refs.body_height.$el.clientHeight:body_height;
            let diff = height-body_height
            return {
                height:height,
                body_height:body_height,
                diff:diff,
                scroll:body_height?diff>180?false:true:false,
            }
        },
        us(){
            return this.$store.getters.getUser
        }, 
        ud(){
            return this.$store.getters.getUserDetails
        },
        loading(){  
            return this.MBS.actions.loading
        },
        processes(){  
            return this.MBS.actions.processes
        }, 
        responses(){  
            return this.MBS.actions.responses
        }, 
        MyCompanies(){ 
            return this.$store.getters.getMyCompanies
        },
        JoinedMyCompanies(){ 
            const companies = this.MyCompanies 
            if(!companies){return null}
            let joined = this.MBS.actions.JOIN_COMPANIES(companies)
            return joined
        },
        selectedCompany(){ 
            return this.$store.getters.getSelectedCompany
        },   
    },
    methods:{  
        SWITCH_COMPANY(company){
            try {   
                let mbs_text = this.MBS.text.switch_company_confirm(company.company_name)
                this.MBS.actions.dialogConfirmPassword({
                    show:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    icon:'mdi-home-switch-outline',
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=SELECT-COMPANY",
                        data:company
                    }
                })

            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'SWITCH_COMPANY',
                    page:PAGE_NAME, 
                })
            }  
        },  
        async SELECT_COMPANY(company){
            try {
                let route = this.route
                let fullPath = route?route.fullPath:null
                let params = route?route.params:null
                let id = params?params.id:null  

                console.log(company,'selecting............c1');
                await this.MBS.actions.SELECT_COMPANY(company)
                if (id) {
                    // let mainPath = fullPath.split(id).pop()
                    let newPath = fullPath.replace(id,company.company_key)
                    this.MBS.actions.go(newPath)
                }
                this.menuSelect = false

            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'SELECT_COMPANY',
                    page:PAGE_NAME, 
                })
            }  
        },  

        //-------------[main]
        DIALOG_YES(action){ 
                try {
                    if (action.code) { 
                        if (action.code ===PAGE_NAME+'=SELECT-COMPANY') {
                            if (action.YES) {
                                setTimeout(() => {    
                                    this.SELECT_COMPANY(action.data) 
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }   
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },
    },
    beforeDestroy(){
        this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);    
    },
    watch:{ 
        MyCompanies(value){  
        },
        route(value){ 
        }, 
        menuSelect(value){ 
            setTimeout(() => {
                this.body_height = this.$refs.body_height?this.$refs.body_height.$el.clientHeight:0;   
            }, 100);
        }
        
    }
}
</script> 